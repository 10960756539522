var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MhRouterView',{staticClass:"DevView view hSpace hSpace--app",attrs:{"isContentReady":_vm.isContentReady}},[_c('div',{staticClass:"view__inner"},[_c('br'),_c('strong',[_vm._v("DevView.vue")]),_c('hr'),_c('br'),_c('br'),_c('br'),_c('mark',{staticClass:"block"},[_vm._v(" MhPageBuilder/v2"),_c('br')]),_c('br'),_c('PagebuilderApp'),_c('br'),_c('br'),_c('mark',{staticClass:"block"},[_vm._v(" MhPageBuilder/v1"),_c('br')]),_c('br'),_c('LayoutContainer'),_c('br'),_c('br'),_c('mark',{staticClass:"block"},[_vm._v(" DgEditor"),_c('br')]),_c('br'),_c('DgEditor',{attrs:{"layout":_vm.dgEditorLayout}}),_c('br'),_c('br'),_c('mark',{staticClass:"block"},[_vm._v(" DgVideoPlayer with autoplay prop."),_c('br')]),_c('br'),_c('DgVideoPlayer',{attrs:{"file":_vm.sampleMp4,"isAutoplay":true,"text":'text-123',"copyright":'copyright-456'}}),_c('br'),_c('br'),_c('mark',{staticClass:"block"},[_vm._v(" DgVideoPlayer with loop and autoplay prop."),_c('br')]),_c('br'),_c('DgVideoPlayer',{attrs:{"file":_vm.sampleMp4,"isAutoplay":true,"isLoop":true,"text":'text-123',"copyright":'copyright-456'}}),_c('br'),_c('br'),_c('mark',{staticClass:"block"},[_vm._v(" DgText with MhSlideToggle example."),_c('br')]),_c('br'),_c('DgText',{attrs:{"fontSize":'xl',"hasIndent":false,"isFolded":true,"text":`
					Beispieltext1<br/>
					Beispieltext2<br/>
					Beispieltext3<br/>
					Beispieltext4<br/>
					Beispieltext5<br/>
					Beispieltext6<br/>
				`}}),_c('br'),_c('br'),_c('mark',{staticClass:"block"},[_vm._v(" MhSlideToggle example."),_c('br')]),_c('br'),_c('button',{on:{"click":function($event){_vm.doSlideToggle = !_vm.doSlideToggle}}},[_vm._v("Do MhSlideToggle")]),_c('pre',{attrs:{"name":"doSlideToggle"}},[_vm._v(_vm._s(_vm.doSlideToggle))]),_c('p',[_vm._v("MhSlideToggle without minHeight")]),_c('br'),_c('MhSlideToggle',{attrs:{"isOpen":_vm.doSlideToggle},on:{"stateChange":_vm.onSlideToggleStateChange}},[_vm._v(" 1"),_c('br'),_vm._v(" 2"),_c('br'),_vm._v(" 3"),_c('br'),_vm._v(" 4"),_c('br'),_vm._v(" 5"),_c('br'),_vm._v(" 6"),_c('br')]),_c('hr'),_c('p',[_vm._v("With with minHeight=\"'3.5em'\"")]),_c('br'),_c('MhSlideToggle',{attrs:{"isOpen":_vm.doSlideToggle,"minHeight":'3.5em'}},[_vm._v(" 1"),_c('br'),_vm._v(" 2"),_c('br'),_vm._v(" 3"),_c('br'),_vm._v(" 4"),_c('br'),_vm._v(" 5"),_c('br'),_vm._v(" 6"),_c('br')]),_c('br'),_c('br'),_c('mark',{staticClass:"block"},[_vm._v(" zoom-on-hover example."),_c('br')]),_c('br'),_c('div',{staticStyle:{"width":"400px"}},[_c('zoom-on-hover',{attrs:{"img-normal":"https://loremflickr.com/640/480/dog","img-zoom":"https://loremflickr.com/640/480/dog","scale":1.5,"disabled":false}})],1),_c('br'),_c('br'),_c('mark',{staticClass:"block"},[_vm._v(" Überblick über diverse Components."),_c('br'),_vm._v(" Aktiviere Debug-Outlines via \"1\" um die Boundries zu sehen. ")]),_c('br'),_c('br'),_c('DgText',{attrs:{"fontSize":'m',"hasIndent":false,"text":'Beispieltext<br/>1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8<br/>9<br/>10'}}),_c('hr'),_c('DgAudioPlayer',{attrs:{"file":_vm.sampleMp3,"text":'Mein fabelhafter Text'}}),_c('hr'),_c('DgAudioPlayer',{attrs:{"file":_vm.sampleMp3,"text":''}}),_c('hr'),_c('DgPlusTextButton',{attrs:{"title":'Marios Sample Title'}}),_c('hr'),_c('DgButton',{attrs:{"type":'menu',"text":_vm.$t('menu')},nativeOn:{"click":function($event){return _vm.onClickMenuBtn('menu')}}}),_c('hr')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }