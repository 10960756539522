var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PageContainer",class:_vm.elmClasses,on:{"click":function($event){$event.stopPropagation();return _vm.eventBusEmit('mhPageBuilder/containerClick', {
		event : $event,
		item : _vm.page,
	})}}},[_c('ContainerGui',{attrs:{"label":'PageContainer • ' + _vm.page.id,"isVisibleAddOnTopBtn":false,"isVisibleAddOnRightBtn":false,"isVisibleAddOnBottomBtn":false,"isVisibleAddOnLeftBtn":false}}),_c('div',{staticClass:"PageContainer__content"},[_c('LayoutContainer',{attrs:{"pageId":_vm._pageId},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.eventBusEmit('mhPageBuilder/containerClick', {
				event : $event,
				item : _vm.layout,
				pageId : _vm._pageId,
			})}}},_vm._l((_vm._.get( _vm.layout, 'sections')),function(section,sectionIndex){return _c('SectionContainer',{key:'s'+sectionIndex,attrs:{"pageId":_vm._pageId,"data":section,"path":`layouts[0].sections[${sectionIndex}]`,"isSelected":_vm.isItemSelected( section.id ),"isHovered":_vm.isItemHovered( section.id ),"isGuiVisible":true,"XXXmouseenter.native":"onContainerMouseEnter( $event, layout, section )","XXXmouseleave.native":"onContainerMouseLeave( $event, layout, section )"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.eventBusEmit('mhPageBuilder/containerClick', {
					event : $event,
					item : section,
					path : `layouts[0].sections[${sectionIndex}]`,
					pageId : _vm._pageId,
				})}}},_vm._l((_vm._.get( section, 'grids')),function(grid,gridIndex){return _c('GridContainer',{key:'g'+gridIndex,attrs:{"pageId":_vm._pageId,"data":grid,"path":`layouts[0].sections[${sectionIndex}].grids[${gridIndex}]`,"isSelected":_vm.isItemSelected( grid.id ),"isHovered":_vm.isItemHovered( grid.id ),"isGuiVisible":true,"XXXclick.native.stop":"onContainerClick( $event, layout, section, grid )","XXXmouseenter.native":"onContainerMouseEnter( $event, layout, section, grid )","XXXmouseleave.native":"onContainerMouseLeave( $event, layout, section, grid )"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.eventBusEmit('mhPageBuilder/containerClick', {
						event : $event,
						item : grid,
						path : `layouts[0].sections[${sectionIndex}].grids[${gridIndex}]`,
						pageId : _vm._pageId,
					})}}},_vm._l((_vm._.get( grid, 'cells')),function(cell,cellIndex){return _c('CellContainer',{key:'c'+cellIndex,attrs:{"pageId":_vm._pageId,"data":cell,"path":`layouts[0].sections[${sectionIndex}].grids[${gridIndex}].cells[${cellIndex}]`,"isSelected":_vm.isItemSelected( cell.id ),"isHovered":_vm.isItemHovered( cell.id ),"isGuiVisible":true,"XXXclick.native.stop":"onContainerClick( $event, layout, section, grid, cell )","XXXmouseenter.native":"onContainerMouseEnter( $event, layout, section, grid, cell )","XXXmouseleave.native":"onContainerMouseLeave( $event, layout, section, grid, cell )"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.eventBusEmit('mhPageBuilder/containerClick', {
							event : $event,
							item : cell,
							path : `layouts[0].sections[${sectionIndex}].grids[${gridIndex}].cells[${cellIndex}]`,
							pageId : _vm._pageId,
						})}}})}),1)}),1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }